import React from "react";
import classes from './WhatYou.module.scss';
import icon_01 from '../../../assets/img/ProductsPage/WhatYou/icon_01.svg';
import icon_02 from '../../../assets/img/ProductsPage/WhatYou/icon_02.svg';
import icon_03 from '../../../assets/img/ProductsPage/WhatYou/icon_03.svg';
import icon_04 from '../../../assets/img/ProductsPage/WhatYou/icon_04.svg';
import icon_05 from '../../../assets/img/ProductsPage/WhatYou/icon_05.svg';
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'products_what_li_01',
    },
    {
        icon: icon_02,
        text: 'products_what_li_02',
    },
    {
        icon: icon_03,
        text: 'products_what_li_03',
    },
    {
        icon: icon_04,
        text: 'products_what_li_04',
    },
    {
        icon: icon_05,
        text: 'products_what_li_05',
    },
];

const WhatYou = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.whatYou}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('products_what_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) => 
                        <div key={index} className={classes.card}>
                            <div className={classes.cardIcon}>
                                <img src={card.icon} alt=''/>
                            </div>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhatYou;
