import React from "react";
import classes from "./Four.module.scss";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/about/4/1.png";
import img2 from "../../../assets/img/about/4/2.png";
import img3 from "../../../assets/img/about/4/3.png";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mt mb"].join(" ")}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.fourTitle, "font-45"].join(" ")}>
            {t('about_winner_title')}
          </h2>
          <p className={[classes.fourSubtitle, "font-20"].join(" ")}>
            {t('about_winner_text')}
          </p>
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <CustomImage
                alt="Winner of more than 20 prestigious awards"
                src={img1}
              />
            </div>
            <div className={[classes.fourRowCenter, ""].join(" ")}>
              <CustomImage
                alt="Winner of more than 20 prestigious awards"
                src={img2}
              />
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <CustomImage
                alt="Winner of more than 20 prestigious awards"
                src={img3}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
