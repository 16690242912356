import React from "react";
import classes from "./Five.module.scss";
import { useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, ""].join(" ")}>
      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.fiveTitle, "font-45"].join(" ")}>
            {t('education_dep_title')}
          </h2>
          <p className={[classes.fiveSubtitle, "font-20"].join(" ")}>
            {t('education_dep_subtitle')}
          </p>
          <div className={[classes.fiveDesk, ""].join(" ")}>
            <div className={[classes.fiveTop, ""].join(" ")}>
              <p className={[classes.fiveTopItem, "font-20"].join(" ")}>
                {t('education_dep_li_01')}
              </p>
              <p className={[classes.fiveTopItem, "font-20"].join(" ")}>
                {t('education_dep_li_02')}
              </p>
            </div>
            <div className={[classes.fiveCenter, ""].join(" ")}>
              <div className={[classes.fiveCenterCircle, ""].join(" ")}>
                <span>1</span>
              </div>
              <div className={[classes.fiveCenterLine, ""].join(" ")}></div>
              <div className={[classes.fiveCenterCircle, ""].join(" ")}>
                <span>2</span>
              </div>
              <div className={[classes.fiveCenterLine, ""].join(" ")}></div>
              <div className={[classes.fiveCenterCircle, ""].join(" ")}>
                <span>3</span>
              </div>
            </div>
            <div className={[classes.fiveText, "font-20"].join(" ")}>
              {t('education_dep_li_03')}
            </div>
          </div>
          <div className={[classes.fiveMobile, ""].join(" ")}>
            <div className={[classes.fiveMobileItem, ""].join(" ")}>
              <div className={[classes.fiveMobileItemCircleWrap, ""].join(" ")}>
                <div className={[classes.fiveMobileItemCircle, ""].join(" ")}>
                  <span>1</span>
                </div>
              </div>
              <div className={[classes.fiveMobileItemText, ""].join(" ")}>
                {t('education_dep_li_01')}
              </div>
            </div>
            <div className={[classes.fiveMobileItemGradientWrap, ""].join(" ")}>
              <div
                className={[classes.fiveMobileItemGradient1, ""].join(" ")}
              ></div>
            </div>

            <div className={[classes.fiveMobileItem, ""].join(" ")}>
              <div className={[classes.fiveMobileItemCircleWrap, ""].join(" ")}>
                <div className={[classes.fiveMobileItemCircle, ""].join(" ")}>
                  <span>2</span>
                </div>
              </div>
              <div className={[classes.fiveMobileItemText, ""].join(" ")}>
                {t('education_dep_li_03')}
              </div>
            </div>
            <div className={[classes.fiveMobileItemGradientWrap, ""].join(" ")}>
              <div
                className={[classes.fiveMobileItemGradient2, ""].join(" ")}
              ></div>
            </div>
            <div className={[classes.fiveMobileItem, ""].join(" ")}>
              <div className={[classes.fiveMobileItemCircleWrap, ""].join(" ")}>
                <div className={[classes.fiveMobileItemCircle, ""].join(" ")}>
                  <span>3</span>
                </div>
              </div>
              <div className={[classes.fiveMobileItemText, ""].join(" ")}>
                {t('education_dep_li_02')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
