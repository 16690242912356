import React from "react";
import classes from './WhyTrade.module.scss';
import image from '../../../assets/img/TradingPage/why_trade.png';
import { useTranslation } from "react-i18next";

const WhyTrade = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.whyTrade}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('platform_why_title')}
                </h2>
                <div className={classes.content}>
                    <img className={classes.image} src={image} quality={100} alt=''/>
                    <div className={classes.cards}>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <p>
                                    {t('platform_why_text_01')}
                                </p>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <p>
                                    {t('platform_why_text_02')}
                                </p>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <p>
                                    {t('platform_why_text_03')}
                                </p>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className={classes.cardContent}>
                                <p>
                                    {t('platform_why_text_04')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyTrade;
