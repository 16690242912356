import React from "react";
import classes from "./Second.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/main/2/1.png";
import img2 from "../../../assets/img/main/2/2.png";
import img3 from "../../../assets/img/main/2/3.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h3 className={[classes.secondTitle, "font-45"].join(" ")}>
            {t('home_acc_types_title')}
          </h3>
          <p className={[classes.secondSubtitle, "font-20"].join(" ")}>
            {t('home_acc_types_text')}
          </p>
          <div className={[classes.secondSlider, ""].join(" ")}>
            <Swiper
              spaceBetween={20}
              slidesPerView={3}
              modules={[Pagination]}
              pagination={true}
              breakpoints={{
                1220: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 2,
                },
                100: {
                  slidesPerView: 1,
                },
              }}
            >
              <SwiperSlide>
                <div className={[classes.slide, ""].join(" ")}>
                  <div className={[classes.slideItem, ""].join(" ")}>
                    <div
                      className={[classes.slideItemImg, classes.img1, ""].join(
                        " "
                      )}
                    >
                      <CustomImage src={img1} alt="Forex Account Types" />
                    </div>
                    <h3
                      className={[classes.slideItemTitle, "font-20"].join(" ")}
                    >
                      Standard
                    </h3>
                    <p
                      className={[classes.slideItemSubtitle, "font-20"].join(
                        " "
                      )}
                    >
                      {t('home_acc_types_min_dep')} $250
                    </p>
                    <ul className={[classes.slideItemList, ""].join(" ")}>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_01_li_01')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_01_li_02')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_01_li_03')}
                      </li>
                    </ul>
                    <div className={[classes.slideItemBtn, ""].join(" ")}>
                      <Button second={"true"}>
                        {t('btn_signup')}
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={[classes.slide, ""].join(" ")}>
                  <div className={[classes.slideItem, ""].join(" ")}>
                    <div
                      className={[classes.slideItemImg, classes.img2, ""].join(
                        " "
                      )}
                    >
                      <CustomImage src={img2} alt="Forex Account Types" />
                    </div>
                    <h3
                      className={[classes.slideItemTitle, "font-20"].join(" ")}
                    >
                      Pro
                    </h3>
                    <p
                      className={[classes.slideItemSubtitle, "font-20"].join(
                        " "
                      )}
                    >
                      {t('home_acc_types_min_dep')} $10.000
                    </p>
                    <ul className={[classes.slideItemList, ""].join(" ")}>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_02_li_01')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_02_li_02')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_02_li_03')}
                      </li>
                    </ul>
                    <div className={[classes.slideItemBtn, ""].join(" ")}>
                      <Button second={"true"}>
                        {t('btn_signup')}
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={[classes.slide, ""].join(" ")}>
                  <div className={[classes.slideItem, ""].join(" ")}>
                    <div
                      className={[classes.slideItemImg, classes.img3, ""].join(
                        " "
                      )}
                    >
                      <CustomImage src={img3} alt="Forex Account Types" />
                    </div>
                    <h3
                      className={[classes.slideItemTitle, "font-20"].join(" ")}
                    >
                      VIP
                    </h3>
                    <p
                      className={[classes.slideItemSubtitle, "font-20"].join(
                        " "
                      )}
                    >
                      {t('home_acc_types_min_dep')} $100.000
                    </p>
                    <ul className={[classes.slideItemList, ""].join(" ")}>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_03_li_01')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_03_li_02')}
                      </li>
                      <li
                        className={[classes.slideItemListItem, "font-20"].join(
                          " "
                        )}
                      >
                        • {t('home_acc_types_03_li_03')}
                      </li>
                    </ul>
                    <div className={[classes.slideItemBtn, ""].join(" ")}>
                      <Button second={"true"}>
                        {t('btn_signup')}
                      </Button>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
