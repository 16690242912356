import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss";
import image from "../../../assets/img/ProductsPage/Banner/image.png";
import gsap from "gsap";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (document.documentElement.clientWidth > 1220) {
        gsap.fromTo(
          imageRef.current,
          {
            x: 50,
          },
          {
            x: 0,
            duration: 1,
            delay: 0.5,
          }
        );
      }
    }
  }, []);
  return (
    <section className={classes.banner}>
      <div className="container">
        <div className={classes.bannerBody}>
          <h1 className={[classes.title, "font-45"].join(" ")}>
            {t('products_banner_title')}
          </h1>
          <div className={[classes.text, "font-20"].join(" ")}>
            <p>
              <b>
                {t('products_banner_text_01')}
              </b>
            </p>
            <p>
              {t('products_banner_text_02')}
            </p>
            <p>
              {t('products_banner_text_03')}
            </p>
          </div>
          <div className={classes.btn}>
            <Button>
              {t('btn_open_account')}
            </Button>
          </div>
          <img
            ref={imageRef}
            quality={100}
            className={classes.image}
            src={image}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Banner;
