import React, { useEffect, useRef } from "react";
import classes from './WhereMore.module.scss';
import graph from '../../../assets/img/TradingPage/ThereMore/graph.png';
import phone from '../../../assets/img/TradingPage/ThereMore/phone.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const WhereMore = () => {
    const { t } = useTranslation();
    const phoneRef = useRef(null);
    const graphRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(graphRef.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: phoneRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container mt">
            <div className={classes.whereMore}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('platform_where_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('platform_where_text')}
                </p>
                <img ref={phoneRef} className={classes.phone} src={phone} alt='' quality={100}/>
                <img ref={graphRef} className={classes.graph} src={graph} alt='' quality={100}/>
            </div>
        </section>
    );
};

export default WhereMore;
