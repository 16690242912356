import React from "react";
import classes from "./Second.module.scss";
import ImageCustom from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/education/2/1.png";
import img2 from "../../../assets/img/education/2/2.png";
import img3 from "../../../assets/img/education/2/3.png";
import img4 from "../../../assets/img/education/2/4.png";
import img5 from "../../../assets/img/education/2/5.png";
import { Trans, useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.secondTitle, "font-45"].join(" ")}>
            {t('education_opp_title')}
          </h2>
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <ImageCustom alt="Opportunities of Members Area" src={img1} />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('education_opp_li_01')}
                </Trans>
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <ImageCustom alt="Opportunities of Members Area" src={img2} />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('education_opp_li_02')}
                </Trans>
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <ImageCustom alt="Opportunities of Members Area" src={img3} />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('education_opp_li_03')}
                </Trans>
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <ImageCustom alt="Opportunities of Members Area" src={img4} />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('education_opp_li_04')}
                </Trans>
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <div className={[classes.secondRowColumnImg, ""].join(" ")}>
                <ImageCustom alt="Opportunities of Members Area" src={img5} />
              </div>
              <p className={[classes.secondRowColumnText, "font-20"].join(" ")}>
                <Trans>
                  {t('education_opp_li_05')}
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
