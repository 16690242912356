import Button from "../../GlobalComponents/Button/Button";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import React from "react";
import classes from "./First.module.scss";
import bgImg from "../../../assets/img/main/1/bg.png";
import img1 from "../../../assets/img/main/1/1.png";
import img2 from "../../../assets/img/main/1/2.png";
import img3 from "../../../assets/img/main/1/3.png";
import img4 from "../../../assets/img/main/1/4.png";
import { useTranslation } from "react-i18next";

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, "mb"].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <h1 className={[classes.firstTitle, "font-45"].join(" ")}>
              {t('home_banner_title')}
            </h1>
            <p className={[classes.firstText, "font-20"].join(" ")}>
              {t('home_banner_text_01')}
            </p>
            <p className={[classes.firstText, "font-20"].join(" ")}>
              {t('home_banner_text_02')}
            </p>
            <div className={[classes.firstBtn, ""].join(" ")}>
              <Button>
                {t('btn_start_trading')}
              </Button>
            </div>
            <div className={[classes.firstBg, ""].join(" ")}>
              <CustomImage src={bgImg} alt={"Welcome to GLM Trade!"} />
            </div>
            <div className={[classes.firstImg1, ""].join(" ")}>
              <CustomImage src={img1} alt={"Welcome to GLM Trade!"} />
            </div>
            <div className={[classes.firstImg2, ""].join(" ")}>
              <CustomImage src={img2} alt={"Welcome to GLM Trade!"} />
            </div>
            <div className={[classes.firstImg3, ""].join(" ")}>
              <CustomImage src={img3} alt={"Welcome to GLM Trade!"} />
            </div>
            <div className={[classes.firstImg4, ""].join(" ")}>
              <CustomImage src={img4} alt={"Welcome to GLM Trade!"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
