import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/about/1/bg.png";
import phone from "../../../assets/img/about/1/phone.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, "mb"].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className={[classes.firstBg, ""].join(" ")}>
          <CustomImage src={img1} alt="" />
        </div>
        <img className={classes.phone} src={phone} alt=''/>
        <div className="container">
          <div className={[classes.firstWrapper, ""].join(" ")}>
            <div className={[classes.first, ""].join(" ")}>
              <div className={[classes.firstRight, ""].join(" ")}>
                <div className={[classes.firstItem, ""].join(" ")}>
                  <h3 className={[classes.firstItemTitle, "font-30"].join(" ")}>
                    20+
                  </h3>
                  <p
                    className={[classes.firstItemSubtitle, "font-20"].join(" ")}
                  >
                    {t('about_banner_li_01')}
                  </p>
                </div>
              </div>
              <div className={[classes.firstLeft, ""].join(" ")}>
                <div className={[classes.firstItem, ""].join(" ")}>
                  <h3 className={[classes.firstItemTitle, "font-30"].join(" ")}>
                    173,000
                  </h3>
                  <p
                    className={[classes.firstItemSubtitle, "font-20"].join(" ")}
                  >
                    {t('about_banner_li_02')}
                  </p>
                </div>
              </div>
              <div className={[classes.firstRight, ""].join(" ")}>
                <div className={[classes.firstItem, ""].join(" ")}>
                  <h3 className={[classes.firstItemTitle, "font-30"].join(" ")}>
                    80,000+
                  </h3>
                  <p
                    className={[classes.firstItemSubtitle, "font-20"].join(" ")}
                  >
                    {t('about_banner_li_03')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
