import React from "react";
import classes from "./Three.module.scss";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/education/3/1.png";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, ""].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <h2 className={[classes.threeRowLeftTitle, "font-45"].join(" ")}>
                {t('education_curr_title')}
              </h2>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t('education_curr_text_01')}
              </p>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t('education_curr_text_02')}
              </p>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t('education_curr_text_03')}
              </p>
            </div>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <CustomImage alt="" src={img1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
