import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from "./Six.module.scss";
import { useTranslation } from "react-i18next";

const Six = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.six, "mt"].join(" ")}>
      <div className={[classes.sixBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.sixRow, ""].join(" ")}>
            <div className={[classes.sixRowLeft, ""].join(" ")}>
              <h2 className={[classes.sixRowLeftTitle, "font-45"].join(" ")}>
                {t('education_start_title')}
              </h2>
              <p className={[classes.sixRowLeftText, "font-20"].join(" ")}>
                {t('education_start_text_01')}
              </p>
              <p className={[classes.sixRowLeftText, "font-20"].join(" ")}>
                {t('education_start_text_02')}
              </p>
              <div className={[classes.sixRowLeftBtn, "font-20"].join(" ")}>
                <Button>
                  {t('btn_open_account')}
                </Button>
              </div>
            </div>
            <div className={[classes.sixRowRight, ""].join(" ")}>
              <h2 className={[classes.sixRowRightTitle, "font-45"].join(" ")}>
                {t('education_verif_title')}
              </h2>
              <p className={[classes.sixRowRightText, "font-20"].join(" ")}>
                {t('education_verif_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
