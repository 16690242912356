import Button from "../../GlobalComponents/Button/Button";
import React, { useRef, useState, useEffect } from "react";
import classes from "./Four.module.scss";
import $ from "jquery";
import img1 from "../../../assets/img/main/3/1.png";
import img2 from "../../../assets/img/main/3/2.png";
import img3 from "../../../assets/img/main/3/3.png";
import img4 from "../../../assets/img/main/3/4.png";
import img5 from "../../../assets/img/main/3/5.png";
import imgLine from "../../../assets/img/main/3/line.png";
import imgTop from "../../../assets/img/main/3/top.png";
import imgBottom from "../../../assets/img/main/3/bottom.png";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const [count, setCount] = useState(1);
  const [items, setItems] = useState([
    // {
    //   id: 0,
    //   img: img5,
    //   ref: ref5,
    //   title: "home_features_card_01_title",
    //   text: "home_features_card_01_text",
    //   posTopOpacity: false,
    //   posTop: true,
    //   posCenter: false,
    //   posBottom: false,
    //   posBottomOpacity: false,
    //   opacity: true,
    // },
    {
      id: 1,
      img: img1,
      ref: ref1,
      title: "home_features_card_02_title",
      text: "home_features_card_02_text",
      posTopOpacity: false,
      posTop: false,
      posCenter: true,
      posBottom: false,
      posBottomOpacity: false,
    },
    {
      id: 2,
      img: img2,
      ref: ref2,
      title: "home_features_card_03_title",
      text: "home_features_card_03_text",
      posTopOpacity: false,
      posTop: false,
      posCenter: false,
      posBottom: true,
      posBottomOpacity: false,
    },
    {
      id: 3,
      img: img3,
      ref: ref3,
      title: "home_features_card_04_title",
      text: "home_features_card_04_text",
      posTopOpacity: false,
      posTop: false,
      posCenter: false,
      posBottom: false,
      posBottomOpacity: true,
    },
    {
      id: 4,
      img: img4,
      ref: ref4,
      title: "home_features_card_05_title",
      text: "home_features_card_05_text",
      posTopOpacity: false,
      posTop: false,
      posCenter: false,
      posBottom: false,
      posBottomOpacity: true,
    },
    {
      id: 5,
      img: img5,
      ref: ref5,
      title: "home_features_card_06_title",
      text: "home_features_card_06_text",
      posTopOpacity: false,
      posTop: false,
      posCenter: false,
      posBottom: false,
      posBottomOpacity: true,
    },
    // {
    //   id: 6,
    //   img: img5,
    //   ref: ref6,
    //   title: "home_features_card_07_title",
    //   text: "",
    //   posTopOpacity: false,
    //   posTop: false,
    //   posCenter: false,
    //   posBottom: false,
    //   posBottomOpacity: true,
    //   opacity: true,
    // },
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    items.map((i) => {
      if (i.posCenter) {
        $(i.ref.current).slideDown(500);
      }
    });
    // eslint-disable-next-line
  }, []);

  const topSlide = () => {
    let prevTemp = count;
    let temp = count - 1;
    let nextTemp = count - 2;
    if (count > 1) {
      setItems(
        // eslint-disable-next-line
        items.map((i) => {
          if (i.id === temp) {
            $(i.ref.current).slideDown(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: true,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (i.id === prevTemp) {
            $(i.ref.current).slideUp(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: false,
              posBottom: true,
              posBottomOpacity: false,
            };
          } else if (i.id === nextTemp) {
            $(i.ref.current).slideUp(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: true,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (
            i.id !== temp &&
            i.id !== prevTemp &&
            i.id !== nextTemp &&
            i.id < temp
          ) {
            return {
              ...i,
              posTopOpacity: true,
              posTop: false,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (
            i.id !== temp &&
            i.id !== prevTemp &&
            i.id !== nextTemp &&
            i.id > temp
          ) {
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: true,
            };
          }
        })
      );
      setCount(count - 1);
    }
  };
  const bottomSlide = () => {
    let prevTemp = count;
    let temp = count + 1;
    let nextTemp = count + 2;
    if (count < 5) {
      setItems(
        // eslint-disable-next-line
        items.map((i) => {
          if (i.id === temp) {
            $(i.ref.current).slideDown(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: true,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (i.id === prevTemp) {
            $(i.ref.current).slideUp(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: true,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (i.id === nextTemp) {
            $(i.ref.current).slideUp(500);
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: false,
              posBottom: true,
              posBottomOpacity: false,
            };
          } else if (
            i.id !== temp &&
            i.id !== prevTemp &&
            i.id !== nextTemp &&
            i.id < temp
          ) {
            return {
              ...i,
              posTopOpacity: true,
              posTop: false,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: false,
            };
          } else if (
            i.id !== temp &&
            i.id !== prevTemp &&
            i.id !== nextTemp &&
            i.id > temp
          ) {
            return {
              ...i,
              posTopOpacity: false,
              posTop: false,
              posCenter: false,
              posBottom: false,
              posBottomOpacity: true,
            };
          }
        })
      );
      setCount(count + 1);
    }
  };

  return (
    <div className={[classes.four, "mt mb"].join(" ")}>
      <div className={classes.fourBody}>
        <div className="container">
          <div className={classes.fourRow}>
            <div className={classes.fourRowLeft}>
              <div className={classes.fourRowLeftItem}>
                <div className={classes.fourRowLeftLine}>
                  <CustomImage alt="Main Features" src={imgLine} />
                </div>
                <div className={classes.fourRowLeftArrows}>
                  <div
                    className={classes.fourRowLeftArrowsTop}
                    onClick={topSlide}
                  >
                    <CustomImage alt="Main Features" src={imgTop} />
                  </div>
                  <div
                    className={classes.fourRowLeftArrowsBottom}
                    onClick={bottomSlide}
                  >
                    <CustomImage alt="Main Features" src={imgBottom} />
                  </div>
                </div>
                {items.map((mainItem) =>
                  <div
                    key={mainItem.id}
                    className={
                      mainItem.posTopOpacity
                        ? [
                            classes.fourRowLeftContent,
                            classes.posTopOpacity,
                            "item",
                          ].join(" ")
                        : mainItem.posTop
                        ? [
                            classes.fourRowLeftContent,
                            classes.posTopOpacity,
                            classes.posTop,
                            "item",
                          ].join(" ")
                        : mainItem.posCenter
                        ? [
                            classes.fourRowLeftContent,
                            classes.posTopOpacity,
                            classes.posTop,
                            classes.posCenter,
                            "item",
                          ].join(" ")
                        : mainItem.posBottom
                        ? [
                            classes.fourRowLeftContent,
                            classes.posTopOpacity,
                            classes.posTop,
                            classes.posCenter,
                            classes.posBottom,
                            "item",
                          ].join(" ")
                        : mainItem.posBottomOpacity
                        ? [
                            classes.fourRowLeftContent,
                            classes.posTopOpacity,
                            classes.posTop,
                            classes.posCenter,
                            classes.posBottom,
                            classes.posBottomOpacity,
                            "item",
                          ].join(" ")
                        : classes.fourRowLeftContent
                    }
                    onClick={() => {
                      if (mainItem.posTop) {
                        topSlide();
                      }
                      if (mainItem.posBottom) {
                        bottomSlide();
                      }
                    }}
                  >
                    <div className={classes.fourRowLeftImg}>
                      <CustomImage alt="Main Features" src={mainItem.img} />
                    </div>
                    <div className={classes.fourRowLeftContentWrap}>
                      <div className={classes.fourRowLeftContentWrapBg}></div>

                      <h3
                        className={mainItem.opacity
                            ? [classes.fourRowLeftContentTitle, classes.opacity, "font-20 no-select"].join(" ")
                            : [classes.fourRowLeftContentTitle, "font-20 no-select"].join(" ")
                        }
                      >
                        {t(mainItem.title)}
                      </h3>
                      <p
                        ref={mainItem.ref}
                        className={[classes.fourRowLeftContentText, "font-18 no-select"].join(" ")}
                      >
                        {t(mainItem.text)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.fourRowRight}>
              <h2 className={[classes.fourRowRightTitle, "font-45"].join(" ")}>
                {t('home_features_title')}
              </h2>
              <p className={[classes.fourRowRightSubtitle, "font-20"].join(" ")}>
                {t('home_features_text')}
              </p>

              <div className={classes.fourMobile}>
                {// eslint-disable-next-line
                items.map((mobI) => {
                  if (!mobI.opacity) {
                    return (
                      <div
                        key={mobI.id}
                        className={classes.fourMobItem}
                      >
                        <h3
                          className={[
                            classes.fourRowLeftContentTitle,
                            "font-20",
                          ].join(" ")}
                        >
                          {t(mobI.title)}
                        </h3>
                        <div
                          className={[
                            classes.fourRowLeftContentText,
                            "font-18",
                          ].join(" ")}
                        >
                          {t(mobI.text)}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              <div className={classes.fourRowRightBtn}>
                <Button>
                  {t('btn_signup')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
