import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import First from "../../components/HomePage/First/First";
import Second from "../../components/HomePage/Second/Second";
import ExpertSupport from "../../components/HomePage/ExpertSupport/ExpertSupport";
import Four from "../../components/HomePage/Four/Four";
import SubscribeNewsletter from "../../components/HomePage/SubscribeNewsletter/SubscribeNewsletter";
import Faq from "../../components/HomePage/Faq/Faq";
import Partners from "../../components/HomePage/Partners/Partners";
import KeysPlayer from "../../components/HomePage/KeysPlayer/KeysPlayer";

const AboutPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>GLM Trade</title>
        <meta name="title" content="GLM Trade | Your Ultimate Trading Platform for Foreign Exchange" />
        <meta property="twitter:title" content="GLM Trade | Your Ultimate Trading Platform for Foreign Exchange" />
        <meta property="og:title" content="GLM Trade | Your Ultimate Trading Platform for Foreign Exchange" />
        <meta name="description" content="Our platform offers state-of-the-art tools and features, top-notch security, and reliable customer support to help you make informed trading decisions and maximize your profits. Start trading today and take your forex journey to the next level with GLM Trade" />
        <meta property="og:description" content="Our platform offers state-of-the-art tools and features, top-notch security, and reliable customer support to help you make informed trading decisions and maximize your profits. Start trading today and take your forex journey to the next level with GLM Trade" />
        <meta property="twitter:description" content="Our platform offers state-of-the-art tools and features, top-notch security, and reliable customer support to help you make informed trading decisions and maximize your profits. Start trading today and take your forex journey to the next level with GLM Trade" />
      </Helmet>
      <Header />
      <First />
      <Second />
      <ExpertSupport />
      <Four />
      <Partners />
      <KeysPlayer />
      <SubscribeNewsletter />
      <Faq />
      <Footer />
    </div>
  );
};

export default AboutPage;
