import React from "react";
import classes from "./CustomImage.module.scss";

const CustomImage = ({ src = "", alt = "", imageRef = null }) => {
  return (
    <img
      quality={100}
      src={src}
      alt={alt}
      className={classes.img}
      ref={imageRef}
    />
  );
};

export default CustomImage;
