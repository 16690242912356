import React from "react";
import classes from './TopPerks.module.scss';
import image_01 from '../../../assets/img/TradingPage/TopPerks/image_01.png';
import image_02 from '../../../assets/img/TradingPage/TopPerks/image_02.png';
import image_03 from '../../../assets/img/TradingPage/TopPerks/image_03.png';
import { Trans, useTranslation } from "react-i18next";

const cards = [
    {
        image: image_01,
        title: 'platform_perks_card_01_title',
        text: 'platform_perks_card_01_text',
    },
    {
        image: image_02,
        title: 'platform_perks_card_02_title',
        text: 'platform_perks_card_02_text',
    },
    {
        image: image_03,
        title: 'platform_perks_card_03_title',
        text: 'platform_perks_card_03_text',
    },
];

const TopPerks = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.topPerks}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    <Trans>
                        {t('platform_perks_title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={[classes.card, 'font-20'].join(' ')}>
                            <img className={classes.cardImage} src={card.image} quality={100} alt=''/>
                            <h4 className={classes.cardTitle}>
                                <Trans>
                                    {t(card.title)}
                                </Trans>
                            </h4>
                            <p className={classes.cardText}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default TopPerks;
