import Button from "../../GlobalComponents/Button/Button";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import React from "react";
import classes from "./SubscribeNewsletter.module.scss";
import img1 from "../../../assets/img/main/4/1.png";
import { useTranslation } from "react-i18next";

const SubscribeNewsletter = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.subscribe, "mt"].join(" ")}>
      <div className={[classes.subscribeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.subscribeRow, ""].join(" ")}>
            <div className={[classes.subscribeRowLeft, ""].join(" ")}>
              <h2
                className={[classes.subscribeRowLeftTitle, "font-45"].join(" ")}
              >
                {t('home_subscribe_title')}
              </h2>
              <p
                className={[classes.subscribeRowLeftText, "font-20"].join(" ")}
              >
                {t('home_subscribe_text_01')}
              </p>
              <p
                className={[classes.subscribeRowLeftText, "font-20"].join(" ")}
              >
                {t('home_subscribe_text_02')}
              </p>
              <div className={[classes.subscribeRowLeftForm, ""].join(" ")}>
                <div
                  className={[classes.subscribeRowLeftFormEmail, ""].join(" ")}
                >
                  <input
                    type="email"
                    required
                    placeholder={t('home_subscribe_placeholder')}
                  />
                </div>
                <div
                  className={[classes.subscribeRowLeftFormSubmit, ""].join(" ")}
                >
                  <Button>
                    {t('btn_start_trading')}
                  </Button>
                </div>
              </div>
            </div>
            <div className={[classes.subscribeRowRight, ""].join(" ")}>
              <CustomImage className={classes.img} alt="" src={img1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
