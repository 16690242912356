import React from "react";
import classes from './Banner.module.scss';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <div className={classes.image}></div>
            <div className="container">
                <div className={classes.bannerBody}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-45'].join(' ')}>
                            {t('accounts_banner_title')}
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t('accounts_banner_text')}
                        </p>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_open_account')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
