import React, { useEffect, useRef, useState } from "react";
import classes from './OrderExecution.module.scss';
import image from '../../../assets/img/TradingPage/OrderExecution/image.png';
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const tabs = [
    {
        id: 1,
        title: 'platform_order_card_01_title',
        text: 'platform_order_card_01_text',
    },
    {
        id: 2,
        title: 'platform_order_card_02_title',
        text: 'platform_order_card_02_text',
    },
];

const OrderExecution = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(imageRef.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container mt">
            <div ref={bodyRef} className={classes.orderExecution}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('platform_order_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('platform_order_text')}
                </p>
                <div className={classes.content}>
                    <div className={classes.tabs}>
                        {tabs.map((tab) =>
                            <div 
                                key={tab.id} 
                                className={`${classes.tab} ${tab.id === currentTab && classes.tabActive} no-select`}
                                onClick={() => setCurrentTab(tab.id)}
                            >
                                <h4 className={classes.tabTitle}>
                                    {t(tab.title)}
                                </h4>
                                <p className={classes.tabText}>
                                    <Trans>
                                        {t(tab.text)}
                                    </Trans>
                                </p>
                            </div>
                        )}
                    </div>
                    <div className={classes.images}>
                        <img ref={imageRef} className={classes.image} src={image} alt='' quality={100} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OrderExecution;
