import React, { useEffect } from "react";
import classes from './ForexTrading.module.scss';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const rows = [
    {
        col_01: 'EUR/USD',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'USD/JPY',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'GBP/USD',
        col_02: '1-2 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'USD/CHF',
        col_02: '1-2 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'AUD/USD',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'USD/CAD',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'EUR/GBP',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
    {
        col_01: 'EUR/JPY',
        col_02: '0.5-1.5 pips',
        col_03: 'Up to 1:500',
    },
];

const ForexTrading = () => {
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo('#forexTradingTable tr:nth-child(even)', {
                    x: 30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#forexTradingTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo('#forexTradingTable tr:nth-child(odd)', {
                    x: -30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#forexTradingTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="mt">
            <div className={classes.forexTrading}>
                <div className="container">
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        {t('products_assets_title')}
                    </h2>
                </div>
                <div className={[classes.container, 'container'].join(' ')}>
                    <div className={classes.tableWr}>
                        <table id="forexTradingTable">
                            <tbody>
                                <tr>
                                    <td>{t('products_assets_col_01')}</td>
                                    <td>{t('products_assets_col_02')}</td>
                                    <td>{t('products_assets_col_03')}</td>
                                </tr>
                                {rows.map((row, index) =>
                                    <tr key={index}>
                                        <td>{row.col_01}</td>
                                        <td>{row.col_02}</td>
                                        <td>{row.col_03}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ForexTrading;
