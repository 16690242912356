import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import First from "../../components/EducationPage/First/First";
import Second from "../../components/EducationPage/Second/Second";
import Three from "../../components/EducationPage/Three/Three";
import Four from "../../components/EducationPage/Four/Four";
import Five from "../../components/EducationPage/Five/Five";
import Six from "../../components/EducationPage/Six/Six";

const EducationPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Education</title>
        <meta name="title" content="GLM Trade Education | Learn to Trade Like a Pro" />
        <meta property="twitter:title" content="GLM Trade Education | Learn to Trade Like a Pro" />
        <meta property="og:title" content="GLM Trade Education | Learn to Trade Like a Pro" />
        <meta name="description" content="With GLM Trade Education, you can access all the knowledge you need to make informed trading decisions and maximize your profits. Start your trading education journey today with GLM Trade" />
        <meta property="og:description" content="With GLM Trade Education, you can access all the knowledge you need to make informed trading decisions and maximize your profits. Start your trading education journey today with GLM Trade" />
        <meta property="twitter:description" content="With GLM Trade Education, you can access all the knowledge you need to make informed trading decisions and maximize your profits. Start your trading education journey today with GLM Trade" />
      </Helmet>
      <Header />
      <First />
      <Second />
      <Three />
      <Four />
      <Five />
      <Six />
      <Footer />
    </div>
  );
};

export default EducationPage;
