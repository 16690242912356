import Button from "../../GlobalComponents/Button/Button";
import React, { useEffect } from "react";
import classes from './ChooseAn.module.scss';
import image_01 from '../../../assets/img/AccountsPage/ChooseAn/image_01.png';
import image_02 from '../../../assets/img/AccountsPage/ChooseAn/image_02.png';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const ChooseAn = () => {
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                $("#chooseAnBtnRef_01").hover(() => {
                    $("#chooseAnImageRef_01").css("transform", "scale(1.1)");
                }, () => {
                    $("#chooseAnImageRef_01").css("transform", "scale(1)");
                });
                $("#chooseAnBtnRef_02").hover(() => {
                    $("#chooseAnImageRef_02").css("transform", "scale(1.1)");
                }, () => {
                    $("#chooseAnImageRef_02").css("transform", "scale(1)");
                });
            }
        }
    }, []);
    return (
        <section className="container mt">
            <div className={classes.chooseAn}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_choose_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('accounts_choose_text')}
                </p>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <img id="chooseAnImageRef_01" className={classes.image} src={image_01} alt='' quality={100}/>
                        <div className={classes.btn}>
                            <Button id="chooseAnBtnRef_01">
                                {t('accounts_choose_btn_01')}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <img id="chooseAnImageRef_02" className={classes.image} src={image_02} alt='' quality={100}/>
                        <div className={classes.btn}>
                            <Button id="chooseAnBtnRef_02">
                                {t('accounts_choose_btn_02')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ChooseAn;
