import React, { useState } from "react";
import classes from "./Second.module.scss";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import { Swiper, SwiperSlide } from "swiper/react";
import arrow from "../../../assets/img/about/2/arrow.png";
import "swiper/css";
import { useTranslation } from "react-i18next";

const Second = () => {
  const { t } = useTranslation();
  const [my_swiper, set_my_swiper] = useState({});
  const [items, setItems] = useState([
    { id: 1, active: true, year: "2016" },
    { id: 2, active: false, year: "2017" },
    { id: 3, active: false, year: "2018" },
    { id: 4, active: false, year: "2019" },
    { id: 5, active: false, year: "2020" },
    { id: 6, active: false, year: "2021" },
    { id: 7, active: false, year: "2022" },
  ]);
  const [content, setContent] = useState([
    {
      id: 1,
      active: true,
      year: "2016",
      title: "about_history_card_01_title",
      text: "about_history_card_01_text",
    },
    {
      id: 2,
      active: false,
      year: "2017",
      title: "about_history_card_02_title",
      text: "about_history_card_02_text",
    },
    {
      id: 3,
      active: false,
      year: "2018",
      title: "about_history_card_03_title",
      text: "about_history_card_03_text",
    },
    {
      id: 4,
      active: false,
      year: "2019",
      title: "about_history_card_04_title",
      text: "about_history_card_04_text",
    },
    {
      id: 5,
      active: false,
      year: "2020",
      title: "about_history_card_05_title",
      text: "about_history_card_05_text",
    },
    {
      id: 6,
      active: false,
      year: "2021",
      title: "about_history_card_06_title",
      text: "about_history_card_06_text",
    },
    {
      id: 7,
      active: false,
      year: "2022",
      title: "about_history_card_07_title",
      text: "about_history_card_07_text",
    },
  ]);
  return (
    <div className={[classes.second, "mb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.secondTitle, "font-45"].join(" ")}>
            {t('about_history_title')}
          </h2>
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[classes.secondRowLeft, ""].join(" ")}
              onClick={() => {
                my_swiper.slidePrev();
              }}
            >
              <CustomImage alt="History and milestones" src={arrow} />
            </div>
            <div className={[classes.secondSlider, ""].join(" ")}>
              <Swiper
                onInit={(ev) => {
                  set_my_swiper(ev);
                }}
                slidesPerView={4}
                breakpoints={{
                  993: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  100: {
                    slidesPerView: 2,
                  },
                }}
              >
                {items.map((i) => {
                  return (
                    <SwiperSlide>
                      <div
                        key={i.id}
                        className={
                          i.active
                            ? [
                                classes.secondSliderSlide,
                                classes.active,
                                "",
                              ].join(" ")
                            : [classes.secondSliderSlide, ""].join(" ")
                        }
                        onClick={() => {
                          setItems(
                            items.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                          setContent(
                            content.map((subI) => {
                              if (i.id === subI.id) {
                                return { ...subI, active: true };
                              } else {
                                return { ...subI, active: false };
                              }
                            })
                          );
                        }}
                      >
                        <div className={classes.secondSliderSlideCircle}></div>
                        <p
                          className={[
                            classes.secondSliderSlideText,
                            "font-20",
                          ].join(" ")}
                        >
                          {i.year}
                        </p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div
              className={[classes.secondRowRight, ""].join(" ")}
              onClick={() => {
                my_swiper.slideNext();
              }}
            >
              <CustomImage alt="History and milestones" src={arrow} />
            </div>
          </div>
          {// eslint-disable-next-line
          content.map((contentI) => {
            if (contentI.active) {
              return (
                <div
                  className={[classes.secondContent, ""].join(" ")}
                  key={contentI.id}
                >
                  <h2
                    className={[classes.secondContentYear, "font-45"].join(" ")}
                  >
                    {contentI.year}
                  </h2>
                  <p
                    className={[classes.secondContentTitle, "font-30"].join(
                      " "
                    )}
                  >
                    {t(contentI.title)}
                  </p>
                  <p
                    className={[classes.secondContentText, "font-20"].join(" ")}
                  >
                    {t(contentI.text)}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Second;
