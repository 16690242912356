import React from "react";
import classes from './OurStats.module.scss';
import person_01 from '../../../assets/img/TradingPage/OurStats/person_01.png';
import person_02 from '../../../assets/img/TradingPage/OurStats/person_02.png';
import bg_01 from '../../../assets/img/TradingPage/OurStats/bg_01.png';
import bg_02 from '../../../assets/img/TradingPage/OurStats/bg_02.png';
import { Trans, useTranslation } from "react-i18next";

const OurStats = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.ourStats}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('platform_stats_title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.card}>
                        <div className={classes.cardContent}>
                            <h4 className={classes.cardTitle}>
                                <Trans>
                                    {t('platform_stats_card_01_title')}
                                </Trans>
                            </h4>
                            <h5 className={classes.cardSubtitle}>
                                {t('platform_stats_card_01_subtitle')}
                            </h5>
                            <p className={classes.cardText}>
                                {t('platform_stats_card_01_text')}
                            </p>
                        </div>
                        <img className={[classes.person, classes.person_01].join(' ')} src={person_01} quality={100} alt=''/>
                        <div className={classes.bg}>
                            <img className={classes.bg_01} src={bg_01} quality={100} alt=''/>
                        </div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.cardContent}>
                            <h4 className={classes.cardTitle}>
                                <Trans>
                                    {t('platform_stats_card_02_title')}
                                </Trans>
                            </h4>
                            <h5 className={classes.cardSubtitle}>
                                {t('platform_stats_card_02_subtitle')}
                            </h5>
                            <p className={classes.cardText}>
                                {t('platform_stats_card_02_text')}
                            </p>
                        </div>
                        <img className={[classes.person, classes.person_02].join(' ')} src={person_02} quality={100} alt=''/>
                        <div className={classes.bg}>
                            <img className={classes.bg_02} src={bg_02} quality={100} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurStats;
