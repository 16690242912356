import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/TradingPage/Banner/Banner";
import TopPerks from "../../components/TradingPage/TopPerks/TopPerks";
import WhyTrade from "../../components/TradingPage/WhyTrade/WhyTrade";
import WhereMore from "../../components/TradingPage/WhereMore/WhereMore";
import WeOffer from "../../components/TradingPage/WeOffer/WeOffer";
import OrderExecution from "../../components/TradingPage/OrderExecution/OrderExecution";
import OurStats from "../../components/TradingPage/OurStats/OurStats";

const TradingPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Trading Platform</title>
        <meta name="title" content="GLM Trade Trading Platform | Experience Powerful and Intuitive Trading" />
        <meta property="twitter:title" content="GLM Trade Trading Platform | Experience Powerful and Intuitive Trading" />
        <meta property="og:title" content="GLM Trade Trading Platform | Experience Powerful and Intuitive Trading" />
        <meta name="description" content="Our platform is designed to help traders of all levels make informed trading decisions and execute trades seamlessly. With a user-friendly interface and customizable features, you can personalize your trading experience to suit your needs" />
        <meta property="og:description" content="Our platform is designed to help traders of all levels make informed trading decisions and execute trades seamlessly. With a user-friendly interface and customizable features, you can personalize your trading experience to suit your needs" />
        <meta property="twitter:description" content="Our platform is designed to help traders of all levels make informed trading decisions and execute trades seamlessly. With a user-friendly interface and customizable features, you can personalize your trading experience to suit your needs" />
      </Helmet>
      <Header />
      <Banner />
      <TopPerks />
      <WhyTrade />
      <WhereMore />
      <OrderExecution />
      <WeOffer />
      <OurStats />
      <Footer />
    </div>
  );
};

export default TradingPage;
