import React, { useRef, useEffect, useState } from "react";
import classes from "./Faq.module.scss";
import $ from "jquery";

import { useTranslation } from "react-i18next";

const Faq = () => {
  const { t } = useTranslation();
  const tab01Ref = useRef(null);
  const tab02Ref = useRef(null);
  const tab03Ref = useRef(null);
  const tab04Ref = useRef(null);
  const tab05Ref = useRef(null);
  const tab06Ref = useRef(null);
  const tab07Ref = useRef(null);
  const tab08Ref = useRef(null);
  const tab09Ref = useRef(null);
  const [tab01Active, setTab01Active] = useState(false);
  const [tab02Active, setTab02Active] = useState(false);
  const [tab03Active, setTab03Active] = useState(false);
  const [tab04Active, setTab04Active] = useState(false);
  const [tab05Active, setTab05Active] = useState(false);
  const [tab06Active, setTab06Active] = useState(false);
  const [tab07Active, setTab07Active] = useState(false);
  const [tab08Active, setTab08Active] = useState(false);
  const [tab09Active, setTab09Active] = useState(false);
  useEffect(() => {
    $(tab01Ref.current).slideUp(0);
    $(tab02Ref.current).slideUp(0);
    $(tab03Ref.current).slideUp(0);
    $(tab04Ref.current).slideUp(0);
    $(tab05Ref.current).slideUp(0);
    $(tab06Ref.current).slideUp(0);
    $(tab07Ref.current).slideUp(0);
    $(tab08Ref.current).slideUp(0);
    $(tab09Ref.current).slideUp(0);
  }, []);
  return (
    <section className="container">
      <div className={classes.faq}>
        <h2 className={[classes.title, "font-45"].join(" ")}>
          {t('home_faqs_title')}
        </h2>
        <p className={[classes.subtitle, "font-20"].join(" ")}>
          {t('home_faqs_text_01')}
        </p>
        <p className={[classes.subtitle, "font-20"].join(" ")}>
          {t('home_faqs_text_02')}
        </p>
        <div className={classes.spoilers}>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab01Ref.current).slideToggle(400);
              setTab01Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_01_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab01Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab01Ref} className={classes.tabContent}>
              {t('home_faqs_01_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab02Ref.current).slideToggle(400);
              setTab02Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_02_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab02Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab02Ref} className={classes.tabContent}>
              {t('home_faqs_02_text')}
            </p>
          </div>
          {/* <div
            className={classes.tab}
            onClick={() => {
              $(tab03Ref.current).slideToggle(400);
              setTab03Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_03_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab03Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab03Ref} className={classes.tabContent}>
              {t('home_faqs_03_text')}
            </p>
          </div> */}
          <div
            className={classes.tab}
            onClick={() => {
              $(tab04Ref.current).slideToggle(400);
              setTab04Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_04_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab04Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab04Ref} className={classes.tabContent}>
              {t('home_faqs_04_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab05Ref.current).slideToggle(400);
              setTab05Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_05_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab05Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab05Ref} className={classes.tabContent}>
              {t('home_faqs_05_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab06Ref.current).slideToggle(400);
              setTab06Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_06_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab06Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab06Ref} className={classes.tabContent}>
              {t('home_faqs_06_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab07Ref.current).slideToggle(400);
              setTab07Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_07_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab07Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab07Ref} className={classes.tabContent}>
              {t('home_faqs_07_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab08Ref.current).slideToggle(400);
              setTab08Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_08_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab08Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab08Ref} className={classes.tabContent}>
              {t('home_faqs_08_text')}
            </p>
          </div>
          <div
            className={classes.tab}
            onClick={() => {
              $(tab09Ref.current).slideToggle(400);
              setTab09Active((value) => !value);
            }}
          >
            <div className={classes.tabTitle}>
              <h3 className={classes.tabTitleText}>
                {t('home_faqs_09_title')}
              </h3>
              <div
                className={`${classes.tabTitleStatus} ${
                  tab09Active && classes.activeStatus
                }`}
              ></div>
            </div>
            <p ref={tab09Ref} className={classes.tabContent}>
              {t('home_faqs_09_text')}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
