import React, { useEffect, useRef } from "react";
import classes from './FastAndSecure.module.scss';
import icon_01 from '../../../assets/img/AccountsPage/FastAndSecure/icon_01.png';
import icon_02 from '../../../assets/img/AccountsPage/FastAndSecure/icon_02.png';
import icon_03 from '../../../assets/img/AccountsPage/FastAndSecure/icon_03.png';
import icon_04 from '../../../assets/img/AccountsPage/FastAndSecure/icon_04.png';
import icon_05 from '../../../assets/img/AccountsPage/FastAndSecure/icon_05.png';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const FastAndSecure = () => {
    const { t } = useTranslation();
    const iconRef_01 = useRef(null);
    const iconRef_02 = useRef(null);
    const iconRef_03 = useRef(null);
    const iconRef_04 = useRef(null);
    const iconRef_05 = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(iconRef_01.current, {
                    y: 50,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=100px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(iconRef_05.current, {
                    y: 50,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=100px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(iconRef_02.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=100px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(iconRef_04.current, {
                    y: 100,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=100px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(iconRef_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=100px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container mt mb">
            <div className={classes.fastAndSecure}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_fast_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('accounts_fast_text')}
                </p>
                <div ref={bodyRef} className={classes.icons}>
                    <img ref={iconRef_01} className={classes.icon} src={icon_01} quality={100} alt=''/>
                    <img ref={iconRef_02} className={classes.icon} src={icon_02} quality={100} alt=''/>
                    <img ref={iconRef_03} className={classes.icon} src={icon_03} quality={100} alt=''/>
                    <img ref={iconRef_04} className={classes.icon} src={icon_04} quality={100} alt=''/>
                    <img ref={iconRef_05} className={classes.icon} src={icon_05} quality={100} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default FastAndSecure;
