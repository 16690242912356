import React from "react";
import classes from './WeOffer.module.scss';
import icon_01 from '../../../assets/img/TradingPage/WeOffer/icon_01.png';
import icon_02 from '../../../assets/img/TradingPage/WeOffer/icon_02.png';
import icon_03 from '../../../assets/img/TradingPage/WeOffer/icon_03.png';
import { useTranslation } from "react-i18next";

const cards = [
    {
        icon: icon_01,
        text: 'platform_offer_li_01',
    },
    {
        icon: icon_02,
        text: 'platform_offer_li_02',
    },
    {
        icon: icon_03,
        text: 'platform_offer_li_03',
    },
];

const WeOffer = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.weOffer}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('platform_offer_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('platform_offer_text')}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div className={classes.card} key={index}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <p className={[classes.cardText, 'font-20'].join(' ')}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WeOffer;
