import React from "react";
import classes from './KeysPlayer.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/main/KeysPlayer/icon_01.png';
import icon_02 from '../../../assets/img/main/KeysPlayer/icon_02.png';
import icon_03 from '../../../assets/img/main/KeysPlayer/icon_03.png';

const cards = [
    {
        title: 'Binance',
        tag: '@Binance',
        text: 'home_keys_card_01_text',
        icon: icon_01,
    },
    {
        title: 'Vitalik Buterin',
        tag: '@VitalikButerin',
        text: 'home_keys_card_02_text',
        icon: icon_02,
    },
    {
        title: 'Bybit',
        tag: '@ByBit',
        text: 'home_keys_card_03_text',
        icon: icon_03,
    },
];

const KeysPlayer = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.keysPlayer}>
                <h2 className={`${classes.title} font-45`}>
                    {t('home_keys_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <p className={`${classes.text} font-18`}>
                                {t(card.text)}
                            </p>
                            <div className={classes.bottom}>
                                <img className={classes.icon} src={card.icon} alt=''/>
                                <div className={`${classes.desc} font-18`}>
                                    <p>
                                        {card.title}
                                    </p>
                                    <p>
                                        {card.tag}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default KeysPlayer;
