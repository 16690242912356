import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import First from "../../components/AboutPage/First/First";
import Second from "../../components/AboutPage/Second/Second";
import Three from "../../components/AboutPage/Three/Three";
import Four from "../../components/AboutPage/Four/Four";
import Five from "../../components/AboutPage/Five/Five";

const AboutPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>About Us</title>
        <meta name="title" content="About GLM Trade | Your Trusted Partner in Forex Trading" />
        <meta property="twitter:title" content="About GLM Trade | Your Trusted Partner in Forex Trading" />
        <meta property="og:title" content="About GLM Trade | Your Trusted Partner in Forex Trading" />
        <meta name="description" content="GLM Trade is a leading forex trading platform, committed to providing traders with a safe, secure, and reliable trading environment. With years of industry experience, our team of experts is dedicated to offering exceptional customer service and helping our clients achieve their trading goals" />
        <meta property="og:description" content="GLM Trade is a leading forex trading platform, committed to providing traders with a safe, secure, and reliable trading environment. With years of industry experience, our team of experts is dedicated to offering exceptional customer service and helping our clients achieve their trading goals" />
        <meta property="twitter:description" content="GLM Trade is a leading forex trading platform, committed to providing traders with a safe, secure, and reliable trading environment. With years of industry experience, our team of experts is dedicated to offering exceptional customer service and helping our clients achieve their trading goals" />
      </Helmet>
      <Header />
      <First />
      <Second />
      <Three />
      <Four />
      <Five />
      <Footer />
    </div>
  );
};

export default AboutPage;
