import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/AccountsPage/Banner/Banner";
import WhyPlatform from "../../components/AccountsPage/WhyPlatform/WhyPlatform";
import ChooseAn from "../../components/AccountsPage/ChooseAn/ChooseAn";
import AvailableAccount from "../../components/AccountsPage/AvailableAccount/AvailableAccount";
import EliteAccounts from "../../components/AccountsPage/EliteAccounts/EliteAccounts";
import FastAndSecure from "../../components/AccountsPage/FastAndSecure/FastAndSecure";
import SubscribeNewsletter from "../../components/HomePage/SubscribeNewsletter/SubscribeNewsletter";

const AccountsPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Accounts</title>
        <meta name="title" content="GLM Trade Accounts | Choose the Perfect Account for Your Trading Needs" />
        <meta property="twitter:title" content="GLM Trade Accounts | Choose the Perfect Account for Your Trading Needs" />
        <meta property="og:title" content="GLM Trade Accounts | Choose the Perfect Account for Your Trading Needs" />
        <meta name="description" content="Our accounts are designed to cater to traders of all levels, from beginners to seasoned professionals, and come with a host of features and benefits. Whether you're looking for low spreads, high leverage, or exclusive perks, GLM Trade has got you covered. Open your account today and start trading like a pro" />
        <meta property="og:description" content="Our accounts are designed to cater to traders of all levels, from beginners to seasoned professionals, and come with a host of features and benefits. Whether you're looking for low spreads, high leverage, or exclusive perks, GLM Trade has got you covered. Open your account today and start trading like a pro" />
        <meta property="twitter:description" content="Our accounts are designed to cater to traders of all levels, from beginners to seasoned professionals, and come with a host of features and benefits. Whether you're looking for low spreads, high leverage, or exclusive perks, GLM Trade has got you covered. Open your account today and start trading like a pro" />
      </Helmet>
      <Header />
      <Banner />
      <WhyPlatform />
      <ChooseAn />
      <AvailableAccount />
      <EliteAccounts />
      <FastAndSecure />
      <SubscribeNewsletter />
      <Footer />
    </div>
  );
};

export default AccountsPage;
