import Button from "../../GlobalComponents/Button/Button";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import { useEffect, useState, useRef } from "react";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/education/1/woman.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const First = () => {
  const { t } = useTranslation();
  const [width, setWidth] = useState();
  const col1 = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(col1.current, {
        y: 0,
        duration: 1,
        delay: 0.5,
      });
    }
  }, [width]);
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, "mb"].join(" ")}>
        <div className="container">
          <div className={[classes.OverWrapper, ""].join(" ")}>
            <div className={[classes.firstWrapper, ""].join(" ")}>
              <h2 className={[classes.firstTitle, "font-45"].join(" ")}>
                {t('education_banner_title')}
              </h2>
              <p className={[classes.firstText, "font-20"].join(" ")}>
                {t('education_banner_text_01')}
              </p>
              <p className={[classes.firstText, "font-20"].join(" ")}>
                {t('education_banner_text_02')}
              </p>
              <div className={[classes.firstBtns, ""].join(" ")}>
                <div className={[classes.firstBtnsLeft, ""].join(" ")}>
                  <Button>
                    {t('btn_open_account')}
                  </Button>
                </div>
              </div>
            </div>
            <div className={[classes.firstWoman, ""].join(" ")}>
              <CustomImage
                alt="Get All Knowledge About Forex at GLM Trade"
                src={img1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
