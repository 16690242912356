import React from "react";
import classes from "./Three.module.scss";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/about/3/1.png";
import { useTranslation } from "react-i18next";

const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, ""].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <div className={[classes.threeRowLeftWrapper, ""].join(" ")}>
                <h2
                  className={[classes.threeRowLeftTitle, "font-45"].join(" ")}
                >
                  {t('about_platform_title')}
                </h2>
                <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                  {t('about_platform_text_01')}
                </p>
                <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                  {t('about_platform_text_02')}
                </p>
              </div>
              <div className={[classes.threeRowLeftBg, ""].join(" ")}>
                <CustomImage alt="About GLM Trade" src={img1} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
