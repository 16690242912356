import React, { useEffect, useRef } from "react";
import classes from './EliteAccounts.module.scss';
import image from '../../../assets/img/AccountsPage/elite_accounts.png';
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const EliteAccounts = () => {
    const { t } = useTranslation();
    const imageRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo(imageRef.current, {
                    y: 200,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="container mt">
            <div ref={bodyRef} className={classes.eliteAccounts}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_elite_title')}
                </h2>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t('accounts_elite_text')}
                </p>
                <ul className="font-20">
                    <li>{t('accounts_elite_li_01')}</li>
                    <li>{t('accounts_elite_li_02')}</li>
                    <li>{t('accounts_elite_li_03')}</li>
                    <li>{t('accounts_elite_li_04')}</li>
                </ul>
                <div className={classes.btn}>
                    <Button>
                        {t('btn_start_trading')}
                    </Button>
                </div>
                <img ref={imageRef} className={classes.image} src={image} alt='' quality={100} />
            </div>
        </section>
    );
};

export default EliteAccounts;
