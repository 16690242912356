import HomePage from '../pages/HomePage/HomePage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import TradingPage from '../pages/TradingPage/TradingPage';
import EducationPage from '../pages/EducationPage/EducationPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/products', element: ProductsPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/trading', element: TradingPage },
    { path: '/education', element: EducationPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/products', element: ProductsPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/education', element: EducationPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'products', text: "header_products" },
    { id: 2, path: 'accounts', text: "header_accounts" },
    { id: 3, path: 'trading', text: "header_trading" },
    { id: 4, path: 'education', text: "header_education" },
    { id: 5, path: 'about', text: "header_about" },
];
