import React, { useState } from "react";
import classes from './WhyPlatform.module.scss';
import image from '../../../assets/img/AccountsPage/why_platform.png';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        id: 1,
        title: 'accounts_why_card_01_title',
        text: 'accounts_why_card_01_text',
    },
    {
        id: 2,
        title: 'accounts_why_card_02_title',
        text: 'accounts_why_card_02_text',
    },
    {
        id: 3,
        title: 'accounts_why_card_03_title',
        text: 'accounts_why_card_03_text',
    },
    {
        id: 4,
        title: 'accounts_why_card_04_title',
        text: 'accounts_why_card_04_text',
    },
];

const WhyPlatform = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    return (
        <section className="container mt">
            <div className={classes.whyPlatform}>
                <h2 className={[classes.title, 'font-45'].join(' ')}>
                    {t('accounts_why_title')}
                </h2>
                <div className={classes.content}>
                    <div className={classes.tabs}>
                        <div className={classes.buttons}>
                            {tabs.map((tab) =>
                                <div 
                                    key={tab.id} 
                                    className={`${classes.button} ${currentTab === tab.id && classes.buttonActive} no-select`}
                                    onClick={() => setCurrentTab(tab.id)}
                                >
                                    {t(tab.title)}
                                </div>
                            )}
                        </div>
                        <div className={classes.text}>
                            <p>{t(tabs[currentTab - 1].text)}</p>
                        </div>
                    </div>
                    <div className={classes.image}>
                        <img quality={100} src={image} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyPlatform;
