import React from "react";
import classes from './Partners.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/main/Partners/icon_01.png';
import icon_02 from '../../../assets/img/main/Partners/icon_02.png';
import icon_03 from '../../../assets/img/main/Partners/icon_03.png';
import icon_04 from '../../../assets/img/main/Partners/icon_04.png';
import icon_05 from '../../../assets/img/main/Partners/icon_05.png';
import icon_06 from '../../../assets/img/main/Partners/icon_06.png';

const cards = [
    {
        icon: icon_01,  
    },
    {
        icon: icon_02,  
    },
    {
        icon: icon_03,  
    },
    {
        icon: icon_04,  
    },
    {
        icon: icon_05,  
    },
    {
        icon: icon_06,  
    },
];

const Partners = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.partners}>
                <h2 className={`${classes.title} font-45`}>
                    {t('home_partners_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <img key={index} src={card.icon} alt='' />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Partners;
