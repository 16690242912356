import React, { useEffect, useRef } from "react";
import classes from './AvailableAccount.module.scss';
import { Trans, useTranslation } from "react-i18next";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const rows = [
    {
        col_01: 'accounts_types_row_01_title',
        col_02: 'accounts_types_col_free',
        col_03: 'accounts_types_col_free',
        col_04: 'accounts_types_col_free',
    },
    {
        col_01: 'accounts_types_row_02_title',
        col_02: 'accounts_types_row_02_col_01',
        col_03: 'accounts_types_row_02_col_02',
        col_04: 'accounts_types_row_02_col_03',
    },
    {
        col_01: 'accounts_types_row_03_title',
        col_02: 'accounts_types_row_03_col_01',
        col_03: 'accounts_types_row_03_col_02',
        col_04: 'accounts_types_row_03_col_03',
    },
    {
        col_01: 'accounts_types_row_04_title',
        col_02: 'accounts_types_row_04_col_01',
        col_03: 'accounts_types_row_04_col_02',
        col_04: 'accounts_types_row_04_col_03',
    },
    {
        col_01: 'accounts_types_row_05_title',
        col_02: '$250',
        col_03: '$10.000',
        col_04: '$100.000',
    },
    {
        col_01: 'accounts_types_row_06_title',
        col_02: 'accounts_types_row_06_col_01',
        col_03: 'accounts_types_row_06_col_02',
        col_04: 'accounts_types_row_06_col_03',
    },
    {
        col_01: 'accounts_types_row_07_title',
        col_02: 'accounts_types_row_07_col_01',
        col_03: 'accounts_types_row_07_col_02',
        col_04: 'accounts_types_row_07_col_03',
    },
    {
        col_01: 'accounts_types_row_08_title',
        col_02: 'accounts_types_yes',
        col_03: 'accounts_types_yes',
        col_04: 'accounts_types_yes',
    },
    {
        col_01: 'accounts_types_row_09_title',
        col_02: 'accounts_types_row_09_col_01',
        col_03: 'accounts_types_row_09_col_02',
        col_04: 'accounts_types_row_09_col_03',
    },
    {
        col_01: 'accounts_types_row_10_title',
        col_02: 'AUD, CAD, CHF, EUR, GBP, HKD,<br/>JPY, NZD, SGD, USD',
        col_03: 'AUD, CAD, CHF, EUR, GBP, HKD,<br/>JPY, NZD, SGD, USD',
        col_04: 'USD, EUR, GBP, AUD',
    },
    {
        col_01: 'accounts_types_row_11_title',
        col_02: 'accounts_types_yes',
        col_03: 'accounts_types_yes',
        col_04: 'accounts_types_yes',
    },
    {
        col_01: 'VPS',
        col_02: 'accounts_types_col_free',
        col_03: 'accounts_types_col_free',
        col_04: 'accounts_types_col_free',
    },
    {
        col_01: 'accounts_types_row_13_title',
        col_02: 'accounts_types_col_free',
        col_03: 'accounts_types_col_free',
        col_04: 'accounts_types_col_free',
    },
    {
        col_01: 'accounts_types_row_14_title',
        col_02: 'accounts_types_col_free',
        col_03: 'accounts_types_col_free',
        col_04: 'accounts_types_col_free',
    },
    {
        col_01: 'accounts_types_row_15_title',
        col_02: 'accounts_types_all_clients',
        col_03: 'accounts_types_all_clients',
        col_04: 'accounts_types_row_15_col_03',
    },
];

const AvailableAccount = () => {
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo('#availableAccountTable tr:nth-child(even)', {
                    x: 30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#availableAccountTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo('#availableAccountTable tr:nth-child(odd)', {
                    x: -30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#availableAccountTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="mt">
            <div className={classes.availableAccount}>
                <div className="container">
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        {t('accounts_types_title')}
                    </h2>
                </div>
                <div className={[classes.container, 'container'].join(' ')}>
                    <div className={classes.tableWr}>
                        <table id="availableAccountTable">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>Standard</td>
                                    <td>Pro</td>
                                    <td>Elite</td>
                                </tr>
                                {rows.map((row, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Trans>
                                                {row.col_01}
                                            </Trans>
                                        </td>
                                        <td>
                                            <Trans>
                                                {row.col_02}
                                            </Trans>
                                        </td>
                                        <td>
                                            <Trans>
                                                {row.col_03}
                                            </Trans>
                                        </td>
                                        <td>
                                            <Trans>
                                                {row.col_04}
                                            </Trans>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td></td>
                                    <td>
                                        <a href="#" className={classes.btn}>
                                            {t('btn_open_account')}
                                        </a>
                                    </td>
                                    <td>
                                        <a href="#" className={classes.btn}>
                                            {t('btn_open_account')}
                                        </a>
                                    </td>
                                    <td>
                                        <a href="#" className={classes.btn}>
                                            {t('btn_open_account')}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AvailableAccount;
