import React from "react";
import Helmet from "react-helmet";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from '../../components/GlobalComponents/Footer/Footer';
import Banner from "../../components/ProductsPage/Banner/Banner";
import WhatYou from "../../components/ProductsPage/WhatYou/WhatYou";
import ForexTrading from "../../components/ProductsPage/ForexTrading/ForexTrading";
import Tabs from "../../components/ProductsPage/Tabs/Tabs";
import SubscribeNewsletter from "../../components/HomePage/SubscribeNewsletter/SubscribeNewsletter";

const ProductsPage = () => {
  return (
    <div className="ovf-hidden">
      <Helmet>
        <title>Products & Prices</title>
        <meta name="title" content="GLM Trade Products & Prices | Discover Our Range of Trading Instruments" />
        <meta property="twitter:title" content="GLM Trade Products & Prices | Discover Our Range of Trading Instruments" />
        <meta property="og:title" content="GLM Trade Products & Prices | Discover Our Range of Trading Instruments" />
        <meta name="description" content="Our competitive pricing and tight spreads ensure that you get the best value for your trades, while our user-friendly platform makes it easy to access the markets and execute trades. Discover our range of trading products and choose the ones that align with your trading strategy. Start trading with GLM Trade today and take your trading to the next level" />
        <meta property="og:description" content="Our competitive pricing and tight spreads ensure that you get the best value for your trades, while our user-friendly platform makes it easy to access the markets and execute trades. Discover our range of trading products and choose the ones that align with your trading strategy. Start trading with GLM Trade today and take your trading to the next level" />
        <meta property="twitter:description" content="Our competitive pricing and tight spreads ensure that you get the best value for your trades, while our user-friendly platform makes it easy to access the markets and execute trades. Discover our range of trading products and choose the ones that align with your trading strategy. Start trading with GLM Trade today and take your trading to the next level" />
      </Helmet>
      <Header />
      <Banner />
      <WhatYou />
      <ForexTrading />
      <Tabs />
      <SubscribeNewsletter />
      <Footer />
    </div>
  );
};

export default ProductsPage;
