import logo from '../assets/img/logo.png';
import img1 from '../assets/img/main/1/1.png';
import img2 from '../assets/img/main/1/2.png';
import img3 from '../assets/img/main/1/3.png';
import img4 from '../assets/img/main/1/4.png';
import img5 from '../assets/img/main/1/bg.png';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
];
