import Button from "../../GlobalComponents/Button/Button";
import React from "react";
import classes from './Banner.module.scss';
import image from '../../../assets/img/TradingPage/banner.png';
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.banner}>
            <div className="container">
                <div className={classes.bannerBody}>
                    <h2 className={[classes.title, 'font-45'].join(' ')}>
                        {t('platform_banner_title')}
                    </h2>
                    <div className={[classes.text, 'font-20'].join(' ')}>
                        <p>
                            {t('platform_banner_text_01')}
                        </p>
                        <p>
                            {t('platform_banner_text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('btn_open_account')}
                        </Button>
                    </div>
                    <img quality={100} className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
