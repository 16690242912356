import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  if (props.login === "true") {
    return (
      <a href="https://user.glmtrade.online/login" {...props} className={classes.button}>
        <span className={classes.btnsvg__label}>{props.children}</span>
      </a>
    );
  }
  if (props.second) {
    return (
      <a href="https://user.glmtrade.online/signup" {...props} className={classes.button1}>
        <span className={classes.btnsvg__label}>{props.children}</span>
      </a>
    );
  }

  return (
    <a href="https://user.glmtrade.online/signup" {...props} className={classes.button}>
      <span className={classes.btnsvg__label}>{props.children}</span>
    </a>
  );
};

export default Button;
