import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import React from "react";
import classes from "./Five.module.scss";
import img1 from "../../../assets/img/about/5/1.png";
import img2 from "../../../assets/img/about/5/2.png";
import img3 from "../../../assets/img/about/5/3.png";
import img4 from "../../../assets/img/about/5/4.png";
import { Trans, useTranslation } from "react-i18next";

const Five = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.five, ""].join(" ")}>
      <div className={[classes.fiveBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.fiveTitle, "font-45"].join(" ")}>
            {t('about_adv_title')}
          </h2>
          <div className={[classes.fiveRow, ""].join(" ")}>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnImg, ""].join(" ")}>
                <CustomImage alt="Our Advantages" src={img1} />
              </div>
              <h3 className={[classes.fiveRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('about_adv_card_01_title')}
                </Trans>
              </h3>
              <p className={[classes.fiveRowColumnText, "font-20"].join(" ")}>
                {t('about_adv_card_01_text')}
              </p>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnImg, ""].join(" ")}>
                <CustomImage alt="Our Advantages" src={img2} />
              </div>
              <h3 className={[classes.fiveRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('about_adv_card_02_title')}
                </Trans>
              </h3>
              <p className={[classes.fiveRowColumnText, "font-20"].join(" ")}>
                {t('about_adv_card_02_text')}
              </p>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnImg, ""].join(" ")}>
                <CustomImage alt="Our Advantages" src={img3} />
              </div>
              <h3 className={[classes.fiveRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('about_adv_card_03_title')}
                </Trans>
              </h3>
              <p className={[classes.fiveRowColumnText, "font-20"].join(" ")}>
                {t('about_adv_card_03_text')}
              </p>
            </div>
            <div className={[classes.fiveRowColumn, ""].join(" ")}>
              <div className={[classes.fiveRowColumnImg, ""].join(" ")}>
                <CustomImage alt="Our Advantages" src={img4} />
              </div>
              <h3 className={[classes.fiveRowColumnTitle, "font-20"].join(" ")}>
                <Trans>
                  {t('about_adv_card_04_title')}
                </Trans>
              </h3>
              <p className={[classes.fiveRowColumnText, "font-20"].join(" ")}>
                {t('about_adv_card_04_text')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Five;
