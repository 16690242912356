import React from "react";
import CustomImage from "../../GlobalComponents/CustomImage/CustomImage";
import img1 from "../../../assets/img/education/4/1.png";
import classes from "./Four.module.scss";
import { useTranslation } from "react-i18next";

const Four = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.four, "mt mb"].join(" ")}>
      <div className={[classes.fourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.fourRow, ""].join(" ")}>
            <div className={[classes.fourRowLeft, ""].join(" ")}>
              <CustomImage alt="" src={img1} />
            </div>
            <div className={[classes.fourRowRight, ""].join(" ")}>
              <h2 className={[classes.fourRowRightTitle, "font-45"].join(" ")}>
                {t('education_leverage_title')}
              </h2>
              <p className={[classes.fourRowRightText, "font-20"].join(" ")}>
                {t('education_leverage_text_01')}
              </p>
              <p className={[classes.fourRowRightText, "font-20"].join(" ")}>
                {t('education_leverage_text_02')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Four;
