import React, { useEffect, useRef, useState } from "react";
import classes from './Tabs.module.scss';
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);

const buttons = [
    {
        id: 1,
        text: 'products_what_li_02',
    },
    {
        id: 2,
        text: 'products_what_li_03',
    },
    {
        id: 3,
        text: 'products_what_li_04',
    },
    {
        id: 4,
        text: 'products_what_li_05',
    },
];

const rows = {
    1: [
        {
            col_01: 'Palladium',
            col_02: 'Palladium',
            col_03: '16.0',
            col_04: '1:20',
        },
        {
            col_01: 'Platinum',
            col_02: 'Platinum',
            col_03: '7.8',
            col_04: 'Floating',
        },
        {
            col_01: 'XAGEUR',
            col_02: 'Silver/Euro',
            col_03: '0.03',
            col_04: '1:100',
        },
        {
            col_01: 'XAGUSD',
            col_02: 'Silver/US Dollar',
            col_03: '0.028',
            col_04: '1:100',
        },
        {
            col_01: 'XAUEUR',
            col_02: 'Gold/Euro',
            col_03: '0.38',
            col_04: '1:500',
        },
        {
            col_01: 'XAUUSD',
            col_02: 'Gold/US Dollar',
            col_03: '0.28',
            col_04: '1:500',
        },
    ],
    2: [
        {
            col_01: 'UKOIL.S',
            col_02: 'UK Brent Oil',
            col_03: '0.07',
            col_04: '1:16',
        },
        {
            col_01: 'USOIL.S',
            col_02: 'US Crude Oil',
            col_03: '0.1',
            col_04: '1:50',
        },
    ],
    3: [
        {
            col_01: 'AMEX',
            col_02: 'NYSE — American Express',
            col_03: '0.4',
            col_04: '1:25',
        },
        {
            col_01: 'Bayer.K',
            col_02: 'EURONEXT — Bayer AG',
            col_03: '0.05',
            col_04: '1:25',
        },
        {
            col_01: 'Adidas.K',
            col_02: 'EURONEXT — Adidas Salomon',
            col_03: '0.26',
            col_04: '1:25',
        },
        {
            col_01: 'Tesla',
            col_02: 'NASDAQ — Tesla Motors',
            col_03: '2.8',
            col_04: '1:25',
        },
        {
            col_01: 'TESCO',
            col_02: 'LSE — Tesco Plc',
            col_03: '0.5',
            col_04: '1:25',
        },
        {
            col_01: 'RollsRoyce',
            col_02: 'LSE — Rolls-Royce',
            col_03: '0.17',
            col_04: '1:25',
        },
    ],
    4: [
        {
            col_01: 'EU50.F',
            col_02: 'EU STOXX50',
            col_03: '3.13',
            col_04: '1:200',
        },
        {
            col_01: 'FRA40.F',
            col_02: 'France 40',
            col_03: '3.08',
            col_04: '1:200',
        },
        {
            col_01: 'GER40.F',
            col_02: 'Germany 40',
            col_03: '4.13',
            col_04: '1:200',
        },
        {
            col_01: 'US500.F',
            col_02: 'US SPX500',
            col_03: '0.83',
            col_04: '1:200',
        },
        {
            col_01: 'US100.F',
            col_02: 'US Tech 100',
            col_03: '3.13',
            col_04: '1:200',
        },
        {
            col_01: 'UK100.F',
            col_02: 'UK 100',
            col_03: '3.68',
            col_04: '1:200',
        },
    ],
};

const Tabs = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(1);
    const tabsRef = useRef(null);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (document.documentElement.clientWidth > 1220) {
                gsap.timeline().fromTo('#productsTabsTable tr:nth-child(even)', {
                    x: 30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#productsTabsTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo('#productsTabsTable tr:nth-child(odd)', {
                    x: -30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: "#productsTabsTable",
                        end: "+=300px",
                        scrub: 3,
                    }
                });
            }
        }
    }, []);
    return (
        <section className="mt mb">
            <div ref={tabsRef} className={classes.tabs}>
                <div className="container">
                    <div className={classes.buttonsWr}>
                        <div className={classes.buttons}>
                            {buttons.map((button) => 
                                <div 
                                    key={button.id} 
                                    className={`${classes.button} ${currentTab === button.id && classes.buttonActive} no-select`}
                                    onClick={() => setCurrentTab(button.id)}
                                >
                                    {t(button.text)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={[classes.container, 'container'].join(' ')}>
                    <div className={classes.tableWr}>
                        <table id="productsTabsTable">
                            <tbody>
                                <tr>
                                    <td>{t('products_tabs_col_01')}</td>
                                    <td>{t('products_tabs_col_02')}</td>
                                    <td>{t('products_tabs_col_03')}</td>
                                    <td>{t('products_tabs_col_04')}</td>
                                </tr>
                                {rows[currentTab].map((row, index) =>
                                    <tr key={index}>
                                        <td>{row.col_01}</td>
                                        <td>{row.col_02}</td>
                                        <td>{row.col_03}</td>
                                        <td>{row.col_04}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tabs;
